import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Box, useMediaQuery, Stack, Typography, Checkbox, FormControlLabel, Divider, Button, TextField, Modal } from '@mui/material';
import './fonts.css'; // Import the fonts CSS file
import logo from './images/maga.webp';
import pepperoni from './images/pepperoni.jpeg';
import Socials from './common/Socials';
import door from './images/door.webp';
import knock from './images/knock.mp3';
import trump from './images/maga_trump.png';

const ca = 'D3gwNP2x9wNbv1YqXbeWg2mgMFNSMXe3no61uKZaEFim';
const pairAddress = '3LFqfU1NCyxvd6VUvPByod2V1NMtuAPRHVyMPFV8vrAH';

const CheckboxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  });
  
  const CustomTypography = styled(Typography)({
    marginRight: '8px',
  });
  
  const CustomImage = styled('img')({
    width: '60px', // Adjust as necessary
    height: '60px', // Adjust as necessary
});
function Home() {
  const [open, setOpen] = useState(false);
  const openDoorAudioRef = useRef(new Audio(knock)); 

  const [ordered, setOrdered] = useState(false);

  const handleClick = () => {
    if (openDoorAudioRef.current) {
        openDoorAudioRef.current.play();
        //wait 1 second the set open true
        setTimeout(() => {
            setOpen(true);
        }, 1000);
    }
  };

  const mobile = useMediaQuery('(max-width:800px)');

  return (
    <Stack>
        {!ordered ? 
        <>
        <Stack direction="row" pt={2} px={10} pb={1}>
            <img src={logo} alt="logo" style={{width: '70px', height: 'auto'}}/>
            <Box sx={{flexGrow: 1}} />
            <Socials size1={40} size2={40} size3={40} />
        </Stack>
        <Divider />
        <Stack spacing={0} p={10} sx={{fontFamily: "HotPizza", maxWidth: '800px'}}>
            <Typography variant="h2" sx={{ fontFamily: 'HotPizza', mb: 4 }}>The Based Dev Pizza </Typography>
            <Typography fontWeight={500}>BUILD YOUR PIZZA</Typography>
            <Typography fontSize={14}>360 cal/slice, 0 slices</Typography>
            <Divider sx={{pt: 2, mb:3}}/>
            <Typography variant="h3" sx={{ fontFamily: 'HotPizza', mb: 1 }}>Crust</Typography>
            <Typography fontSize={14}>Extra Large, The Based Dev(6 slices)</Typography>
            <Divider sx={{pt: 3, mb:3}}/>
            <Typography variant="h3" sx={{ fontFamily: 'HotPizza', mb: 1 }}>Sauce & Cheese</Typography>
            <Typography fontSize={14}>Sweet Marinara, Regular Cheese</Typography>
            <Divider sx={{pt: 3, mb:3}}/>
            <Typography variant="h3" sx={{ fontFamily: 'HotPizza', mb: 1 }}>Toppings</Typography>
            <div>
                <CheckboxContainer>
                    <FormControlLabel
                    control={<Checkbox />}
                    label={
                        <CustomTypography>Pepperoni</CustomTypography>
                    }
                    />
                    <CustomImage src={pepperoni} alt="pepporini" />
                </CheckboxContainer>
            </div>
            <Button sx={{bgcolor: "red", color: "white", mt: 2}} size="large" onClick={()=>setOrdered(true)}>Order Now</Button>
        </Stack> </>: 
        <>
        <Stack direction="row" pt={2} px={10} pb={1}>
            <img src={logo} alt="logo" style={{width: '70px', height: 'auto'}}/>
            <Box sx={{flexGrow: 1}} />
            <Socials size1={40} size2={40} size3={40} />
        </Stack>
        <Divider />
        <Box display="flex" justifyContent="center" mt={2}>
            {!open ? 
            <img src={door} alt="door" onClick={handleClick}  style={{width: "40vw", cursor: "pointer"}} />
            :
            <Stack>
            <img src={trump} alt="trump" style={{width: "20vw" }} />
            <Typography variant="h3" sx={{mt: 2}} >CA: </Typography>
            </Stack>
            }
        </Box>
        </>
        }
    </Stack>
  );
}

export default Home;